import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/iosdownload',
    name: 'iosdownload',
    component: () => import('../views/IosDownLoad.vue')
  },
  {
    path: '/help-1',
    name: 'iosdownload0',
    component: () => import('../views/Help_1.vue')
  },
  {
    path: '/help-2',
    name: 'iosdownload1',
    component: () => import('../views/Help_2.vue')
  },
  {
    path: '/help-3',
    name: 'iosdownload2',
    component: () => import('../views/Help_3.vue')
  },
  {
    path: '/help-4',
    name: 'iosdownload3',
    component: () => import('../views/Help_4.vue')
  },
  {
    path: '/promoters',
    name: "promoters",
    component: () => import('../views/promoters.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
