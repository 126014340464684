import Vue from 'vue'
import App from './App.vue'
import router from './router'
import messages from './message.json'

Vue.config.productionTip = false
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

//获取当前的页面时什么端
if (isMobileDevice()) {
  Vue.prototype.device = 'mobile'
} else {
  Vue.prototype.device = 'desktop'
}
function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

const i18n = new VueI18n({
  locale: 'zh',
  messages,
})

new Vue({
  router,
  render: h => h(App),
  i18n
}).$mount('#app')
