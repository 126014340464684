<template>
  <div id="app" :class="device">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  mounted() {
  }
}
</script>

<style>
@import url(./assets/css/reset.css);
</style>